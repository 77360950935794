
import React from 'react';
import icoLoading from '../../Resources/Images/Loading.svg';

class Loading extends React.Component {

    render() {

        return (

            <img src={icoLoading} alt="Loading" />

        );

    }
}
export default Loading;