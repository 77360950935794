import React from "react";
import ReactDOM from "react-dom";
import MainLayout from './Layout/MainLayout';
import './Resources/CSS/style.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import ReactGA from 'react-ga';
require('typeface-rajdhani');
require('typeface-lato');


ReactGA.initialize('UA-116902905-1');

ReactDOM.render(<MainLayout />, document.getElementById("root"));