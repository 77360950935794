
import React, { Suspense, lazy } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import Loading from '../Components/Loading/Loading';
//import SignUp from '../Sections/MSSubscription/SignUp';
const TopHeader = lazy(() => import('../Layout/TopHeader'));
const Home = lazy(() => import('../Sections/Home/HomePage'));
const Solutions = lazy(() => import('../Sections/Solutions/SolutionPage'));
const Platforms = lazy(() => import('../Sections/Platform/Platform'));
const Services = lazy(() => import('../Sections/Services/Services'));
const Pricing = lazy(() => import('../Sections/Pricing/Pricing'));
const ContactUs = lazy(() => import('../Sections/ContactUs/ContactUs'));
const CustomAction = lazy(() => import('../Sections/ContactUs/CustomContact'));
const CustomLink = lazy(() => import('../Sections/ContactUs/CustomLink'));
const ThankYou = lazy(() => import('../Sections/ContactUs/ThankYou'));
const About = lazy(() => import('../Sections/About/About'));
const Footer = lazy(() => import('./Footer'));
const Privacy = lazy(() => import('../Sections/Privacy/Privacy'));
const Linkpage = lazy(() => import('../Sections/LinkPages/LinkPage'))


class MainLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isFooterVisible: true, subPage: null, currentURL: "" };
        this.FooterVisibility = this.FooterVisibility.bind(this)

    }

    FooterVisibility(footerVisibility) {
        const { isFooterVisible } = this.state
        if (isFooterVisible !== footerVisibility) {
            this.setState({ isFooterVisible: footerVisibility })
        }
        return footerVisibility;
    }

    componentDidMount() {


        if (window.location.href.endsWith(".com") && document.referrer.length > 0 && document.referrer.toLowerCase().indexOf('linkedin') > -1) {

            window.location = window.location.href + "/Home";

        } else if (window.location.href.endsWith(".com/") && document.referrer.length > 0 && document.referrer.toLowerCase().indexOf('linkedin') > -1) {

            window.location = window.location.href + "Home";
        }


        this.addToHeadAsyncSRC('https://www.googletagmanager.com/gtag/js?id=UA-116902905-1');
        this.addToHeadAsyncSRC('https://www.googletagmanager.com/gtag/js?id=AW-1014849592');
        this.addToHeadTag("window.dataLayer = window.dataLayer || [];function gtag() { dataLayer.push(arguments); }gtag('js', new Date());gtag('config', 'UA-116902905-1', { 'optimize_id': 'GTM-PWVCG3S' });");
        this.addToHeadTag("(function (w, d, s, l, i) {w[l] = w[l] || []; w[l].push({'gtm.start':new Date().getTime(), event: 'gtm.js'}); var f = d.getElementsByTagName(s)[0],j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src ='https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);})(window, document, 'script', 'dataLayer', 'GTM-WJC628Q');")
        this.addToHeadTag("window.dataLayer = window.dataLayer || [];function gtag() { dataLayer.push(arguments); }gtag('js', new Date());gtag('config', 'AW-1014849592');")
        this.addToHeadTag("(function(c,l,a,r,i,t,y){c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) }; t = l.createElement(r); t.async = 1; t.src = 'https://www.clarity.ms/tag/' + i; y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y); })(window, document, 'clarity', 'script', '5hum7m3xq6');");


        this.addToBodyBottom("_linkedin_partner_id = '316851';window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];window._linkedin_data_partner_ids.push(_linkedin_partner_id);");
        this.addToBodyBottom("(function () {var s = document.getElementsByTagName('script')[0];var b = document.createElement('script');b.type = 'text / javascript'; b.async = true;b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';s.parentNode.insertBefore(b, s);})();");

        let bodyTag = document.createElement('noscript');
        let imgElement = document.createElement('img')
        imgElement.height = '1';
        imgElement.width = '1';
        imgElement.style = 'display:none;';
        imgElement.alt = '';
        imgElement.src = 'https://dc.ads.linkedin.com/collect/?pid=316851&fmt=gif'
        bodyTag.appendChild(imgElement);
        document.body.appendChild(bodyTag);


    }
    addToHeadTag(innerHtml) {
        let headerScripts = document.createElement('script');
        headerScripts.innerHTML = innerHtml;
        document.head.appendChild(headerScripts);
    }
    addToHeadAsyncSRC(url) {
        let headerScripts = document.createElement('script');
        headerScripts.src = url;
        headerScripts.async = true;
        document.head.appendChild(headerScripts);
    }

    addToBodyBottom(innerHtml) {
        let headerScripts = document.createElement('script');
        headerScripts.type = "text/javascript";
        headerScripts.innerHTML = innerHtml;
        document.body.appendChild(headerScripts);
    }
    componentDidUpdate(prevProps, prevState) {

        const { currentURL } = this.state;
        alert("stored url: " + currentURL);
        if (currentURL === "" || currentURL !== window.location.pathname) {
            alert("old Path: " + currentURL + "\\n New URL: " + window.location.pathname)
        }

    }





    render() {
        const { isFooterVisible, baseURL } = this.state;

        return (

            <BrowserRouter>
                <Suspense fallback={<div className="d-flex w-100 justify-content-center align-items-center text-center  p-1"><div className="d-flex ImageSize2"><Loading /></div></div>}>

                    <div style={{ maxWidth: '2000px', margin: '0 auto' }}><TopHeader />

                        <Switch>
                            {
                                baseURL === true
                                    ? <Redirect to="/Home" />
                                    : null
                            }
                            <Route path="/About" render={() => (<About />)} />

                            {//Solutions - All solutions need path otherwise Netlify do not prerender
                                //If not pre render twitter and linkedIn cards don't work   
                            }
                            <Route path="/Solutions/AuditManagement" render={(props) => (<Solutions solution="AuditManagement" footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Solutions/BusinessContinuityManagement" render={(props) => (<Solutions solution="BusinessContinuityManagement" footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Solutions/RemediationManagement" render={(props) => (<Solutions solution="RemediationManagement" footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Solutions/Assurance" render={(props) => (<Solutions solution="Assurance" footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Solutions/EnterpriseRiskManagement" render={(props) => (<Solutions solution="EnterpriseRiskManagement" footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Solutions/ITRiskManagement" render={(props) => (<Solutions solution="ITRiskManagement" footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Solutions/VendorManagement" render={(props) => (<Solutions solution="VendorManagement" footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Solutions/ComplianceManagement" render={(props) => (<Solutions solution="ComplianceManagement" footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Solutions/EnvironmentalHealthSafety" render={(props) => (<Solutions solution="EnvironmentalHealthSafety" footerVisibility={this.FooterVisibility} />)} />

                            <Route path="/Solutions/:solution" render={(props) => (<Solutions solution={props.match.params.solution} footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Solutions/" render={(props) => (<Solutions footerVisibility={this.FooterVisibility} />)} />

                            {//Platform - All Platform need path otherwise Netlify do not prerender
                                //If not pre render twitter and linkedIn cards don't work   
                            }
                            <Route path="/Platforms/Risks" render={(props) => (<Platforms platform="Risks" footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Platforms/Standards" render={(props) => (<Platforms platform="Standards" footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Platforms/MonitoringMetrics" render={(props) => (<Platforms platform="MonitoringMetrics" footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Platforms/TasksNotification" render={(props) => (<Platforms platform="TasksNotification" footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Platforms/ReportDashboard" render={(props) => (<Platforms platform="ReportDashboard" footerVisibility={this.FooterVisibility} />)} />

                            <Route path="/Platforms/:platform" render={(props) => (<Platforms platform={props.match.params.platform} footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Platforms/" render={(props) => (<Platforms footerVisibility={this.FooterVisibility} />)} />

                            {//Services - All Services need path otherwise Netlify do not prerender
                                //If not pre render twitter and linkedIn cards don't work   
                            }
                            <Route path="/Services/Managed-Services" render={(props) => (<Services service="Managed-Services" footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Services/Implementation-Services" render={(props) => (<Services service="Implementation-Services" footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Services/Maintenance-Support" render={(props) => (<Services service="Maintenance-Support" footerVisibility={this.FooterVisibility} />)} />

                            <Route path="/Services/:service" render={(props) => (<Services service={props.match.params.service} footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Services/" render={(props) => (<Services footerVisibility={this.FooterVisibility} />)} />

                            <Route path="/Pricing/" render={(props) => (<Pricing footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/ContactUs/" render={(props) => (<ContactUs footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Privacy/" render={(props) => (<Privacy footerVisibility={this.FooterVisibility} />)} />

                            <Route path="/CustomLink/:page/:action" render={(props) => (<CustomLink footerVisibility={this.FooterVisibility} page={props.match.params.page} action={props.match.params.action} />)} />
                            <Route path="/CustomAction/" render={(props) => (<CustomAction footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Sucess/ThankYou" render={(props) => (<ThankYou footerVisibility={this.FooterVisibility} />)} />

                            <Route path="/What-is-Integrated-Risk-Management" render={(props) => (<Linkpage pageId={"What-Is-Integrated-Risk-Management"} footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Risk-Assessment" render={(props) => (<Linkpage pageId={"Risk-Assessment"} footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Integrated-Risk-Management" render={(props) => (<Linkpage pageId={"Integrated-Risk-Management"} footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Details/Learnings-From-Integrated-Risk-Management" render={(props) => (<Linkpage pageId={"Learnings-From-Integrated-Risk-Management"} footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Details/Governance-Risk-Compliance" render={(props) => (<Linkpage pageId={"Governance-Risk-Compliance"} footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/Integrated-Risk-Management-Comprehensive-Guide" render={(props) => (<Linkpage pageId={"Integrated-Risk-Management-Comprehensive-Guide"} footerVisibility={this.FooterVisibility} />)} />
                            
                            <Route path="/risk-management-guide" render={(props) => (<Linkpage pageId={"risk-management-guide"} footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/aligning-your-companys-grc-strategy-with-industry-best-practices" render={(props) => (<Linkpage pageId={"aligning-your-companys-grc-strategy-with-industry-best-practices"} footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/audit-management-system" render={(props) => (<Linkpage pageId={"audit-management-system"} footerVisibility={this.FooterVisibility} />)} />
                            
                            {/* FAQ Pages */}
                            <Route path="/FAQ/:pageId/:question" render={(props) => (<Linkpage pageId={props.match.params.pageId} itemsToCollapse={[props.match.params.question]} footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/FAQ/:pageId" render={(props) => (<Linkpage pageId={props.match.params.pageId} footerVisibility={this.FooterVisibility} />)} />
                            {/* <Route path="/integrated-risk-management-faqs/:question" render={(props) => (<Linkpage pageId={"integrated-risk-management-faqs"} itemsToCollapse={[props.match.params.question]} footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/integrated-risk-management-faqs" render={(props) => (<Linkpage pageId={"integrated-risk-management-faqs"} footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/FAQ/:pageId" render={(props) => (<Linkpage pageId={props.match.params.pageId} footerVisibility={this.FooterVisibility} />)} />     */}


                            {/* NZ Related Articles */}
                            <Route path="/nz/guide-to-new-zealand-public-sector-risk-and-assurance" render={(props) => (<Linkpage pageId={"guide-to-new-zealand-public-sector-risk-and-assurance"} footerVisibility={this.FooterVisibility} />)} />
                            <Route path="/nz/new-zealand-public-sector-risk-assurance" render={(props) => (<Linkpage pageId={"new-zealand-public-sector-risk-assurance"} footerVisibility={this.FooterVisibility} />)} />
                            
                            

                            {/* <Route path="/MSSubscription" render={(props) => (<SignUp footerVisibility={this.FooterVisibility} />)} /> */}


                            <Route path="/Demo" render={(props) => (<CustomAction footerVisibility={this.FooterVisibility} title="Request Demo" subject="Demo" header="Please fill out the form so that we can get in touch with you for a demo." />)} />
                            <Route path="/Home" render={() => (<Home footerVisibility={this.FooterVisibility} />)} />

                            <Route path="/*" render={() => (<Home footerVisibility={this.FooterVisibility} />)} />
                            <Route path="" render={() => (<Home footerVisibility={this.FooterVisibility} />)} />

                        </Switch>
                        {isFooterVisible ? <Footer /> : null}
                    </div>
                </Suspense>

            </BrowserRouter >
        );

    }
}
export default MainLayout;